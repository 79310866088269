import PropTypes from "prop-types";
import React from "react";
import { List } from "antd";
import "antd/dist/antd.css";

const Tecnologia = ({ nombre, items }) => (
  <div style={{ marginBottom: "0.5em" }}>
    <h3 style={{ marginBottom: "0.25em" }}>{nombre}</h3>
    <List
      style={{ backgroundColor: "transparent" }}
      dataSource={items}
      renderItem={(item, i) => (
        <span>
          {item}
          {i === items.length - 1 ? "." : ", "}
        </span>
      )}
    />
  </div>
);

Tecnologia.propTypes = {
  items: PropTypes.array,
  nombre: PropTypes.string
};

export default Tecnologia;
