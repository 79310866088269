import React from "react";

export default [
  {
    nombre: "Desarrollo Web",
    icon: <img src="/images/web.jpg" />,
    descripcion: `Hemos transitado desde los 90s con los cgi-bin en Perl hasta los actuales frameworks de Javascript con Front
    y Back separados, pasando por supuesto por la época dorada de JQuery con Ajax: Podemos trabajar en la tecnología
    que tengas, pero te aconsejamos: Java, React, GraphQL y Linux`,
    tecnologias: [
      { nombre: "Lenguajes", descripcion: "", items: ["Java 8", "JavaScript ES6", "Perl5"] },
      {
        nombre: "Frameworks",
        descripcion: "",
        items: ["SpringBoot", "Play Framework 1.5", "ReactJS"]
      },
      { nombre: "IDEs", descripcion: "", items: ["Eclipse", "Atom", "SpringToolSuite"] },
      { nombre: "Gráficas", descripcion: "", items: ["Inkscape", "Gimp", "Pencil"] },
      { nombre: "Bases de Datos", descripcion: "", items: ["PostgreSQL", "Oracle", "MySQL"] },
      { nombre: "Kits CSS", descripcion: "", items: ["Bootstrap", "Semantic-UI", "Material-UI"] }
    ]
  },
  {
    nombre: "Desarrollo Móvil",
    icon: <img src="/images/mobile.jpg" />,
    descripcion: `Desarrollamos aplicaciones móviles nativas multiplataforma. Nuestras
    aplicaciones se comunican con nuestros sistemas centrales en forma transparente, convirtiéndose
    en canales avanzados de captura y despliegue de información. Tenemos apps que usan
    Mapas, Bluetooth y Notificaciones Push.`,
    tecnologias: [
      { nombre: "Lenguajes", items: ["JavaScript ES6"] },
      { nombre: "Frameworks", items: ["React Native"] },
      { nombre: "IDE", items: ["Atom"] }
    ]
  },
  {
    nombre: "Capacitación",
    icon: <img src="/images/capacitacion.jpg" />,
    descripcion: `Reacondicionamos a tus viejos programadores web, que usan PHP y JQuery. no es necesario que
    los cambies. Los programadores debemos salir continuamente de la zona de confort y superar el "Culto a lo que estoy usando".`,
    tecnologias: [
      { nombre: "Java", items: ["Java 8", "Test Driven Development"] },
      { nombre: "UML", items: ["Casos de Uso", "Clases", "Máquina de Estados"] },
      { nombre: "React", items: ["Javascript 6", "ReactJS", "ReactNative", "Redux"] },
      { nombre: "PostgreSQL", items: ["Administración Servidores", "Performance Tips"] }
    ]
  },
  {
    nombre: "Data Warehouse",
    icon: <img src="/images/warehouse.jpg" />,
    descripcion: `La inteligencia de negocios y el data warehouse, son dos especialidades que
    van de la mano, y son la extensión natural una vez que tenemos nuestro
    sistema operacional capturando datos. Hemos creado soluciones completas usando
    Pentaho: Modelado de Solución con Kimball, programación de ETL, Cubos, OLAP con Jpivot y ahora Saikú.`,
    tecnologias: [
      { nombre: "Metodología", items: ["Ralph Kimball"] },
      { nombre: "Frameworks", items: ["Pentaho", "Mondrian", "Saiku", "Schema-Workbench"] }
    ]
  }
];
