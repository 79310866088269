import PropTypes from "prop-types";
import React from "react";
import "antd/dist/antd.css";
import Tecnologia from "./Tecnologia";

const Servicio = ({ nombre, descripcion, tecnologias }) => (
  <div style={{ marginBottom: "1.5em" }}>
    <h2 style={{ marginBottom: "0.5em" }}>{nombre}</h2>

    {tecnologias.map(tec => <Tecnologia key={tec.nombre} {...tec} />)}
  </div>
);

Servicio.propTypes = {
  descripcion: PropTypes.string,
  nombre: PropTypes.string,
  tecnologias: PropTypes.string
};

export default Servicio;
