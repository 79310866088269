import PropTypes from "prop-types";
import React from "react";
import { Card, Col } from "antd";
import "antd/dist/antd.css";

const ColumnServicio = ({ nombre, descripcion, icon }) => (
  <Col xs={24} sm={12} md={12} lg={8} xl={10}>
    <Card hoverable cover={icon} style={{ marginBottom: "1em" }}>
      <Card.Meta title={nombre} description={descripcion} />
    </Card>
  </Col>
);

ColumnServicio.propTypes = {
  descripcion: PropTypes.string,
  icon: PropTypes.object,
  nombre: PropTypes.string
};

export default ColumnServicio;
